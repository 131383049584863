.cardBg {
    @apply dark:bg-bgDarkPrimary bg-bgLightPrimary;
}

.borderMain {
    @apply border-[1px] border-gray-500 border-opacity-20;
}

.textMain {
    @apply dark:text-gray-100 text-gray-800;
}

.textPrimary {
    @apply dark:text-gray-400 text-gray-600
}

.flex-center {
    @apply flex items-center justify-center;
}