
.custom-calendar.rmdp-wrapper,
.rmdp-container .custom-calendar.ep-arrow::after {
    background-color: #161a1e !important;
}

.rmdp-month-picker{
    background-color: #161a1e !important;
}

.rmdp-container {
    width: 100%;
}
